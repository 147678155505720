import { when } from '../../lib/when'
import type { AnalyticsCollector } from '../collector'

export function initPostHogScreenRecording(ko: AnalyticsCollector) {
  const condition = () =>
    typeof window.posthog === 'object' && typeof window.posthog.get_session_replay_url === 'function'

  const callback = () => {
    const sessionURL = window.posthog.get_session_replay_url()

    if (sessionURL) {
      ko.identify(
        {
          posthog_session_url: sessionURL,
          posthog_session_id: window.posthog.get_session_id(),
          posthog_distinct_id: window.posthog.get_distinct_id()
        },
        {
          source: 'posthog'
        }
      )
    }
  }

  when(condition, callback, { timeout: 100, retries: 10 })
}
