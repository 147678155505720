export interface PageDefault {
  path: string
  referrer: string
  title: string
  url: string
  host: string
}

export function pageDefaults(): PageDefault {
  return {
    path: window.location.pathname,
    referrer: document.referrer,
    title: document.title,
    url: window.location.href,
    host: window.location.host
  }
}
