import Cookies, { CookieAttributes } from 'js-cookie'
import { hostName } from './session'

function getCookieOptions(options?: CookieAttributes) {
  const domain = hostName(options?.domain)
  return {
    ...options,
    domain: domain
  }
}

export function setCookie(key: string, value: string, options?: CookieAttributes) {
  // protect against bad input from the Koala app, such as someone adding the URL protocol
  // or a specific path to the cookie domain
  Cookies.set(key, value, getCookieOptions(options))

  return value
}

export function getCookie(key: string) {
  return Cookies.get(key)
}

export function removeCookie(key: string, options?: CookieAttributes) {
  const value = Cookies.get(key)
  Cookies.remove(key, getCookieOptions(options))
  return value
}

export function allowedCookieDomain(
  detectedTopLevelDomain: string | undefined,
  explicitCookieDomain: string | undefined
) {
  if (detectedTopLevelDomain === explicitCookieDomain) {
    return true
  }

  if (window.location.hostname === explicitCookieDomain) {
    return true
  }

  const clean = hostName(explicitCookieDomain)

  if (!detectedTopLevelDomain && !clean) {
    return true
  }

  if ((detectedTopLevelDomain || '').includes(clean)) {
    return true
  }

  return false
}
