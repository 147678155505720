import type { AnalyticsCollector } from '../collector'

export function initArcade(ko: AnalyticsCollector) {
  window.addEventListener(
    'message',
    (event) => {
      if (event.origin === 'https://demo.arcade.software') {
        if (ko && event.data && event.data.eventName) {
          ko.track('Arcade ' + event.data.eventName, event.data, {
            source: 'arcade'
          })
        }
      }
    },
    false
  )
}
