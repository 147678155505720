import { when } from '../../lib/when'
import type { AnalyticsCollector } from '../collector'

export function initIntercom(ko: AnalyticsCollector) {
  const condition = () => typeof window.Intercom === 'function'

  const callback = () => {
    const visitorId = window.Intercom('getVisitorId')
    if (ko && visitorId) {
      ko.identify({ intercom__visitor_id: visitorId }, { source: 'intercom' })
    }

    window.Intercom('onUserEmailSupplied', () => {
      try {
        const intercomIframe = document.querySelector('.intercom-messenger-frame')?.children[0] as HTMLIFrameElement

        if (intercomIframe && ko && !ko.email) {
          setTimeout(() => {
            const content = intercomIframe.contentDocument
            const email = (content?.body.querySelector('input[type="email"]') as HTMLInputElement)?.value

            if (email) {
              ko.identify({ email }, { source: 'intercom' })
            }
          }, 100)
        }
      } catch (error) {
        ko?.trackError(error as Error, 'intercom.onUserEmailSupplied')
      }
    })
  }

  when(condition, callback, { timeout: 100, retries: 10 })
}
