import { AnalyticsCollector, CollectorOptions } from './collector'
import { PageDefault, pageDefaults } from './page/page-info'
import { session, Session } from './session'
import { UserStore } from './user'

function deviceType() {
  const ua = window.navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet'
  }

  if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return 'mobile'
  }

  return 'desktop'
}

export interface EventOptions {
  source?: string
}

export interface Context {
  page?: PageDefault
  userAgent?: string

  platform?: {
    name?: string
    deviceType?: string
  }

  locale?: string
  library?: {
    name: string
    version?: string
  }

  session?: Session
  timezone?: string
  [key: string]: any
}

function safeDefaults() {
  try {
    return pageDefaults()
  } catch (_err) {
    // return undefined if there is an error
  }
}

function timeZone() {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  } catch (_err) {
    // return undefined if browser doesn't have support
  }
}

export class EventContext {
  options: CollectorOptions
  user: UserStore

  constructor(options?: CollectorOptions) {
    this.options = options || ({} as CollectorOptions)
    this.user = new UserStore({
      cookies: this.options.sdk_settings?.cookie_defaults
    })
  }

  public session(): Session {
    return session.fetch(this.options.sdk_settings)
  }

  public current = (type: 'page' | 'event' | 'metric' | 'identify' | 'submit'): Context => {
    const page = type === 'page' ? undefined : safeDefaults()
    const collector = window.ko as AnalyticsCollector | undefined

    return {
      page,
      userAgent: window.navigator.userAgent,
      platform: {
        name: window.navigator.platform,
        deviceType: deviceType()
      },
      library: {
        name: 'koala',
        version: collector?.version
      },
      session: this.session(),
      locale: window.navigator.language,
      timezone: timeZone()
    }
  }
}
