import type { Consumer, Mixin, Subscription } from '@rails/actioncable'
import { AnalyticsCollector } from '../analytics/collector'

type OnReceivedFn = (data: any) => void

export type ProfileSubscription = Subscription &
  Mixin & {
    connected: () => void
    disconnected: () => void
    received: OnReceivedFn
    rejected: () => void
  }

export function createProfileSubscription(
  client: Consumer,
  instance: AnalyticsCollector,
  onReceived: OnReceivedFn
): ProfileSubscription {
  return client.subscriptions.create(
    { channel: 'ProfileChannel', sid: instance.session.id },
    {
      isConnected: false,
      connected() {
        this.isConnected = true
      },
      disconnected() {
        this.isConnected = false
      },
      rejected() {
        this.isConnected = false
      },
      received: onReceived
    }
  )
}
