import type { AnalyticsCollector } from '../collector'

const storylane_events: Record<string, string> = {
  demo_open: 'Demo Opened',
  demo_finished: 'Demo Finished',
  lead_identify: 'Lead Identified',
  step_view: 'Step Viewed',
  open_external_url: 'External URL Opened',
  checklist_item_view: 'Checklist Item Viewed',
  flow_start: 'Flow Started',
  flow_end: 'Flow Ended',
  page_view: 'Page Viewed',
  primary_cta: 'Primary CTA Clicked',
  secondary_cta: 'Secondary CTA Clicked',
  convert_cta: 'Convert CTA Clicked'
}

function isStorylaneEvent(e: MessageEvent) {
  return e.data.message === 'storylane-demo-event'
}

// https://docs.storylane.io/storylane-knowledge-base/integrations/integrations/cross-frame-events
export function initStorylane(ko: AnalyticsCollector) {
  window.addEventListener('message', (event) => {
    try {
      if (!ko || !event.data || !isStorylaneEvent(event)) return false

      const properties = event.data.payload || {}
      const eventName = properties.event

      if (eventName === 'lead_identify' && !ko.email && properties.lead?.email) {
        ko.identify(properties.lead, { source: 'storylane' })
      } else if (storylane_events[eventName]) {
        ko.track('Storylane ' + storylane_events[eventName], properties, { source: 'storylane' })
      }
    } catch (error) {
      ko?.trackError(error as Error, 'initStorylane')
    }
  })
}
