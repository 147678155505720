import { Context } from '../analytics/event-context'
import { sendBeacon } from './fetch'

export interface Metric {
  context: Context
  id?: string
  name: string
  sent_at: Date
  properties?: { [key: string]: unknown }
}

interface Params {
  project: string
  profile_id?: string
  metrics: Metric[]
}

export function metrics({ project, ...params }: Params) {
  return sendBeacon(`/web/projects/${project}/metrics`, params)
}
