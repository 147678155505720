import Cookies from 'js-cookie'

export function levels(url: string | URL): string[] {
  url = typeof url === 'string' ? new URL(url) : url

  const host = url.hostname
  const parts = host.split('.')
  const last = parts[parts.length - 1]
  const levels: string[] = []

  // Ip address.
  if (parts.length === 4 && last === String(parseInt(last, 10))) {
    return levels
  }

  // Localhost.
  if (parts.length <= 1) {
    return levels
  }

  // Create levels.
  for (let i = parts.length - 2; i >= 0; --i) {
    levels.push(parts.slice(i).join('.'))
  }

  return levels
}

export function topDomain(url: URL): string | undefined {
  const lvls = levels(url)

  // Lookup the real top level one.
  for (let i = 0; i < lvls.length; ++i) {
    const name = '__tld__'
    const domain = lvls[i]
    const opts = { domain: '.' + domain }

    Cookies.set(name, '1', opts)
    if (Cookies.get(name)) {
      Cookies.remove(name, opts)
      return domain
    }
  }
}
