export function isSamePage(actual: string | URL, expected?: string | URL): boolean {
  try {
    actual = new URL(actual, window.location.origin)
    expected = new URL(expected || window.location.href, window.location.origin)

    return actual.hostname === expected.hostname && actual.pathname === expected.pathname
  } catch (_error) {
    return false
  }
}
