import type { AnalyticsCollector } from '../collector'
import { pageDefaults } from '../page/page-info'

// hubspot trait names to standard trait names
// though we should move this server-side probably
const traitMap: Record<string, string> = {
  email: 'email',
  jobtitle: 'title',
  hs_role: 'role',
  phone: 'phone',
  mobilephone: 'mobile_phone',
  lastname: 'last_name',
  firstname: 'first_name',
  fullname: 'name',
  twitterhandle: 'twitter_handle',
  hs_linkedin_url: 'linkedin_url'
}

const traitNames = Object.keys(traitMap)

export function initHubSpot(ko: AnalyticsCollector) {
  // hook into hubspot form submission and meeting bookings
  window.addEventListener('message', (event) => {
    if (!ko || !event.data) return false

    try {
      if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
        const formId = event.data.id
        const formData = event.data.data

        if (!formData || !formData.submissionValues || !formId) {
          return
        }

        const traits: Record<string, string> = {}
        const submissionValues = { ...formData.submissionValues }
        delete submissionValues.hs_context

        for (const trait of traitNames) {
          if (submissionValues[trait]) {
            traits[traitMap[trait]] = submissionValues[trait]
          }
        }

        // only track traits from the form if it's a new email or the same email
        const email = traits.email
        if (!ko.email || email == ko.email) {
          ko.identify(traits, { source: 'hubspot_form' })
        }

        ko.track('$submit', {
          method: 'POST',
          name: `#hsForm_${formId}`,
          formData: submissionValues,
          traits: traits,
          context: {
            page: pageDefaults()
          }
        })
      } else if (event.data.meetingBookSucceeded) {
        const booking = event.data.meetingsPayload?.bookingResponse || {}
        const contact = booking.postResponse?.contact
        const email = contact?.email
        if (email && !ko.email) {
          ko.identify({ email }, { source: 'hubspot_meeting' })
        }

        ko.track(
          'Meeting Booked',
          {
            integration: 'HubSpot',
            contact,
            organizer: booking.postResponse?.organizer,
            meeting_date: booking.event?.dateString
          },
          {
            source: 'hubspot_meeting'
          }
        )
      }
    } catch (error) {
      ko?.trackError(error as Error, 'initHubSpot')
    }
  })
}
