export function inViewport(element: HTMLElement) {
  const { top, left, right, bottom } = element.getBoundingClientRect()
  const width = element.offsetWidth
  const height = element.offsetHeight

  if (
    top >= -height &&
    left >= -width &&
    right <= (window.innerWidth || document.documentElement.clientWidth) + width &&
    bottom <= (window.innerHeight || document.documentElement.clientHeight) + height
  ) {
    return true
  } else {
    return false
  }
}
