import { when } from '../../lib/when'
import type { AnalyticsCollector } from '../collector'

export function initFullstory(ko: AnalyticsCollector) {
  const condition = () => typeof window.FS === 'function'

  const callback = () => {
    window.FS('observe', {
      type: 'start',
      callback: () => {
        const sessionURL = window.FS('getSession', { format: 'url' })
        if (sessionURL) {
          ko.identify(
            {
              fullstory_session_url: sessionURL
            },
            {
              source: 'fullstory'
            }
          )
        }
      }
    })

    window.addEventListener('fullstory/rageclick', (e: any) => {
      ko.track(
        'Rage Click Detected',
        {
          replay_url: e?.detail?.eventReplayUrlAtCurrentTime
        },
        {
          source: 'fullstory'
        }
      )
    })
  }

  when(condition, callback, { timeout: 100, retries: 10 })
}
