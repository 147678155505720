import type { AnalyticsCollector as KoalaSDK } from './analytics/collector'
import * as KoalaBrowser from './browser'
import { IgnoredForm } from './analytics/forms'
import { getNamespace } from './browser'

export { load } from './browser'

declare global {
  // eslint-disable-next-line no-var
  var koalaSettings:
    | {
        host?: string
        wssHost?: string
        // Allow users to set the anonymous profile id before loading the SDK
        profileId?: string
        sdk_settings?: {
          autocapture?: boolean
          autotrack_arcade?: boolean
          autotrack_calendly?: boolean
          autotrack_drift?: boolean
          autotrack_intercom?: boolean
          autotrack_navattic?: boolean
          autotrack_qualified?: boolean
          autotrack_fullstory?: boolean
          autotrack_posthog_screen_recording?: boolean
          autotrack_rudderstack?: boolean
          segment_auto_track?: 'on' | 'off'
          form_collection?: 'on' | 'off'
          websocket_connection?: 'on' | 'off'
          authorized_referrers?: string[]
          ignored_forms?: IgnoredForm[]
        }
      }
    | undefined

  interface Window {
    ko?: KoalaSDK
    koala?: KoalaSDK
    globalKoalaKey?: string
  }
}

/**
 * Get the project slug from data attributes or the script's url.
 */
function getProject(): string | undefined {
  let project = document.currentScript?.getAttribute('data-project')

  if (!project) {
    const [_file, id] = (document.currentScript?.getAttribute('src') || '').split('/').reverse()

    project = id
  }

  return project
}

function getProfileId(): string | undefined {
  return window.koalaSettings?.profileId
}

/**
 * Loads the Koala SDK
 */
async function loadSDK() {
  const project = getProject()

  if (!project) {
    console.error('Koala SDK: Missing required project attribute.')
    return
  }

  const ns = getNamespace()

  try {
    const profileId = getProfileId()
    // @ts-expect-error the types are wrong
    window[ns] = await KoalaBrowser.load({ project, profileId })
  } catch (error) {
    console.error('[KOALA]', 'Failed to load the Koala SDK.', error)
  }
}

/**
 * Automatically load the SDK when the script is parsed and executed
 */
loadSDK()
