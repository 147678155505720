export function domReady(fn?: () => Promise<unknown> | unknown, doc?: Document): Promise<void> {
  return new Promise((resolve) => {
    const resolver = async () => {
      if (fn) {
        await fn()
      }
      resolve()
    }

    const leDoc = doc || document

    if (leDoc.readyState === 'complete' || leDoc.readyState === 'interactive') {
      setTimeout(resolver, 0)
    } else {
      leDoc.addEventListener('DOMContentLoaded', resolver)
    }
  })
}
