type EventArgs<T> = T extends any[] ? T : T extends any ? [T] : never[]
type EventReceiver<T> = (...args: EventArgs<T>) => void
interface EventMap {
  [event: string]: any
}

export class Emitter<M extends EventMap = any> {
  private callbacks: {
    [E in keyof M]?: Array<EventReceiver<M[E]>>
  } = {}

  on<E extends keyof M>(event: E, callback: EventReceiver<M[E]>): this {
    if (!this.callbacks[event]) {
      this.callbacks[event] = []
    }
    this.callbacks[event]!.push(callback)
    return this
  }

  once<E extends keyof M>(event: E, fn: EventReceiver<M[E]>): this {
    const on = (...args: EventArgs<M[E]>): void => {
      this.off(event, on)
      fn.apply(this, args)
    }

    this.on(event, on)
    return this
  }

  off<E extends keyof M>(event: E, callback: EventReceiver<M[E]>): this {
    const fns = this.callbacks[event]
    if (fns) {
      const without = fns.filter((fn) => fn !== callback)
      this.callbacks[event] = without
    }
    return this
  }

  emit<E extends keyof M>(event: E, ...args: EventArgs<M[E]>): this {
    const fns = this.callbacks[event]
    if (fns) {
      fns.forEach((fn) => fn.apply(this, args))
    }
    return this
  }

  removeListeners() {
    this.callbacks = {}
  }
}
