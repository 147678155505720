import type { AnalyticsCollector } from '../collector'

function isCalendlyEvent(e: MessageEvent) {
  return e.origin === 'https://calendly.com' && e.data.event && e.data.event.indexOf('calendly.') === 0
}

export function initCalendly(ko: AnalyticsCollector) {
  // hook into calendly iframe events
  window.addEventListener('message', (event) => {
    if (!ko || !event.data || !isCalendlyEvent(event)) return false

    const eventName = event.data.event

    if (eventName === 'calendly.event_scheduled') {
      const properties = event.data.payload || {}
      ko.track('Calendly Event Scheduled', properties, { source: 'calendly' })
    }
  })
}
