import { sendBeacon } from './fetch'

interface Params {
  project: string
  profile_id: string
  utk: string
}

export function utk({ project, ...params }: Params) {
  return sendBeacon(`/web/projects/${project}/utk`, params)
}
