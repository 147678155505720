import { v4 as uuid } from '@lukeed/uuid'
import type { Metric } from '../api/metrics'
import * as api from '../api/metrics'
import type { EventContext } from './event-context'
import { Queue, QueueOptions } from './queue'

export class MetricsQueue extends Queue<Metric> {
  projectSlug: string
  context: EventContext

  constructor(options: QueueOptions<Metric> = {}, projectSlug: string, ctx: EventContext) {
    super(options)
    this.projectSlug = projectSlug
    this.context = ctx
  }

  increment(name: string, properties?: { [key: string]: unknown }) {
    this.add({
      context: this.context.current('metric'),
      id: uuid(),
      name,
      properties,
      sent_at: new Date()
    })
  }

  // Override the default onSend method to send the metrics to the server
  protected onSend(metrics: Metric[] = []) {
    const profile_id = this.context.user.id()
    const project = this.projectSlug

    if (!project || !profile_id) {
      return false
    }

    return api.metrics({
      project,
      profile_id,
      metrics
    })
  }
}
